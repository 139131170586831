html, body, * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  width: 100vw;
  color: #009cde;
  background-color: #e54f23;
  flex-direction: column;
  font-family: Averia Sans Libre, Fredoka One, Poppins, sans-serif;
  display: flex;
}

h1, h2, h3, h4 {
  font-family: Fredoka One, Poppins, sans-serif;
}

h1 {
  text-align: center;
  font-size: 3rem;
}

h2 {
  text-align: center;
  color: #e54f23;
  margin-bottom: 1rem;
  font-size: 2rem;
}

h3 {
  color: #e54f23;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

p {
  text-align: justify;
  margin-bottom: .8rem;
  font-size: 1.3rem;
  font-weight: normal;
}

a {
  color: #fff;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: #000;
}

ul, ol {
  margin-left: 2rem;
}

ul li, ol li {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: normal;
}

label {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: normal;
  display: block;
}

input, textarea, select {
  color: #000;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #dfdfdf;
  outline: none;
  margin-bottom: 1rem;
  padding: .5rem;
}

input[type="submit"], textarea[type="submit"], select[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #009cde;
  border: none;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  transition: background-color .3s;
}

input[type="submit"]:hover, textarea[type="submit"]:hover, select[type="submit"]:hover {
  background-color: #000;
}

@media (max-width: 750px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  p, ul li {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

.form {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

img {
  max-width: 100%;
}

header {
  width: 100%;
  opacity: .9;
  z-index: 1;
  background-color: #fff;
  padding: .8rem 1rem;
  position: fixed;
}

header .content {
  flex-direction: row;
  align-items: center;
}

header .logo {
  width: 200px;
  max-width: 80px;
}

header nav ul {
  gap: 1.5rem;
  list-style: none;
  display: flex;
}

header nav ul li a {
  color: #000;
  font-size: 1rem;
  font-weight: lighter;
  text-decoration: none;
}

.content {
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 auto;
  display: flex;
}

main {
  width: 100%;
  position: relative;
}

.anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -150px;
}

.full-height {
  min-height: calc(100vh - 200px);
}

.only {
  justify-content: center;
  align-items: center;
  display: flex;
}

section {
  color: #009cde;
  width: 100%;
  background-color: #fff;
  border-top: 2px solid #dfdfdf;
  padding: 4rem 1rem;
}

section a {
  color: #009cde;
  text-decoration: none;
  transition: color .3s;
}

section a:hover {
  color: #000;
}

footer {
  width: 100%;
  color: #fff;
  background-color: #009cde;
  padding: 2rem 1rem;
}

footer .content {
  flex-direction: row;
  gap: 1rem;
  display: flex;
}

footer p {
  margin: 0;
  font-size: 1rem;
}

@media only screen and (max-width: 600px) {
  main {
    font-size: 10vw;
  }
}

#inicio {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#inicio .nombre {
  margin-bottom: 1rem;
  font-family: Pacifico, cursive, Poppins, sans-serif;
  font-size: 5rem;
}

#inicio h3 {
  font-size: 2rem;
  font-weight: lighter;
}

@media (max-width: 600px) {
  #inicio .nombre {
    font-size: 10vw;
  }

  #inicio h3 {
    font-size: 5vw;
  }
}

#inicio .portada {
  max-width: 500px;
  margin: 0 auto 2rem;
}

#inicio .tags {
  max-width: 500px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-left: 0;
  list-style: none;
  display: flex;
}

#inicio .tags li {
  --size: 4;
  font-size: calc(var(--size) * .25rem + .5rem);
  line-height: calc(var(--size) * .25rem + .5rem);
  padding: .125rem 1.25rem;
  text-decoration: none;
  display: block;
  position: relative;
}

#inicio .tags li[data-weight="1"] {
  --size: 1;
}

#inicio .tags li[data-weight="2"] {
  --size: 2;
}

#inicio .tags li[data-weight="3"] {
  --size: 3;
}

#inicio .tags li[data-weight="4"] {
  --size: 4;
}

#inicio .tags li[data-weight="5"] {
  --size: 6;
}

#inicio .tags li[data-weight="6"] {
  --size: 8;
}

#inicio .tags li[data-weight="7"] {
  --size: 10;
}

#inicio .tags li[data-weight="8"] {
  --size: 13;
}

#inicio .tags li[data-weight="9"] {
  --size: 16;
}

#inicio .tags[data-show-value] li:after {
  content: " (" attr(data-weight) ")";
  font-size: 1.5rem;
}

#inicio .tags li:focus {
  outline: 1px dashed;
}

#inicio .tags li:before {
  content: "";
  width: 0;
  height: 100%;
  opacity: .15;
  transition: width .25s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

#inicio .tags li:focus:before, #inicio .tags li:hover:before {
  width: 100%;
}

@media (prefers-reduced-motion) {
  #inicio .tags ul {
    transition: none !important;
  }
}

@media (max-width: 768px) {
  #inicio .tags li[data-weight="1"] {
    --size: .5;
  }

  #inicio .tags li[data-weight="2"] {
    --size: 1;
  }

  #inicio .tags li[data-weight="3"] {
    --size: 1.5;
  }

  #inicio .tags li[data-weight="4"] {
    --size: 2;
  }

  #inicio .tags li[data-weight="5"] {
    --size: 2.5;
  }

  #inicio .tags li[data-weight="6"] {
    --size: 3;
  }

  #inicio .tags li[data-weight="7"] {
    --size: 3.5;
  }

  #inicio .tags li[data-weight="8"] {
    --size: 4;
  }

  #inicio .tags li[data-weight="9"] {
    --size: 4.5;
  }
}

.first {
  padding-top: 100px;
}

.donde-estamos .info {
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  display: grid;
}

.donde-estamos .direccion {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.donde-estamos .direccion p {
  margin-bottom: 1rem;
}

.donde-estamos .direccion strong {
  min-width: 120px;
  display: inline-block;
}

@media (max-width: 768px) {
  .donde-estamos .info {
    grid-template-columns: 1fr;
  }

  .donde-estamos .mapa iframe {
    width: 100%;
  }
}

.sobre-nosotros .portada {
  width: 100%;
}

.sobre-nosotros .content {
  padding-top: 2rem;
  display: block;
}

.sobre-nosotros .fotos {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  display: grid;
}

@media screen and (max-width: 600px) {
  .sobre-nosotros .fotos {
    grid-template-columns: 1fr;
  }
}

.servicios .fotos {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.fondo .logos {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fondo .logos img {
  max-width: 200px;
  width: 100%;
}

.fondo .implantacion {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.fondo .implantacion img {
  max-width: 600px;
  width: 100%;
}

@media (max-width: 768px) {
  .fondo .logos {
    flex-direction: column;
  }
}

.politicas p, .politicas li {
  font-size: 1rem;
}

table thead {
  vertical-align: middle;
  border-color: inherit;
  display: table-header-group;
}

table tbody {
  vertical-align: middle;
  border-color: inherit;
  display: table-row-group;
}

table td {
  padding: 0 .5rem;
}

table th {
  text-align: center;
  background: #ccc;
  padding: 0 .5rem;
}

#cookies {
  color: #000;
  max-width: 340px;
  background: rgba(182, 178, 181, .8);
  border-radius: 5px;
  padding: 1rem;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#cookies .wrapper {
  margin: 0;
  padding: 0;
}

#cookies p {
  font-size: .8rem;
}

#cookies .botones {
  text-align: right;
}

#cookies .botones a, #cookies .botones button {
  font-size: 1rem;
}

#cookies .botones button {
  color: #fff;
  background-color: #2e9248;
  border: 0;
  border-radius: 3px;
  margin-left: 1rem;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.cita {
  gap: 1rem;
  display: flex;
}

.glass {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, .2);
  border: 1px solid rgba(255, 255, 255, .3);
}

.bounce {
  animation: 5s infinite bounce;
  position: absolute;
  bottom: 2rem;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.arrowDown {
  border: 1px solid #009cde;
  border-width: 0 1px 1px 0;
  padding: 1rem;
  display: inline-block;
  transform: rotate(45deg);
  box-shadow: 15px 14px 24px -20px #fff;
}

.objetivos {
  margin-bottom: 4rem;
}

.objetivos h3 {
  color: #e54f23;
}

.objetivos ul {
  margin-top: 1rem;
}

.objetivos ul li {
  margin-bottom: .7rem;
  font-size: 1.2rem;
}

.objetivos ul li ul li {
  font-size: 1rem;
}

.objetivos .flex {
  gap: 2rem;
  display: flex;
}

@media (max-width: 750px) {
  .objetivos .flex {
    flex-direction: column;
  }

  .objetivos .flex ul li {
    font-size: 1rem;
  }

  .objetivos .flex ul li ul {
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 1rem -1rem;
    display: flex;
  }

  .objetivos .flex ul li ul li {
    list-style: none;
  }
}

.cards {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

.cards .card {
  border: 3px solid #e54f23;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

.cards .card h3 {
  text-align: center;
  width: 100%;
  padding: 1rem;
}

.cards .card .content {
  flex-direction: row;
  padding: 0;
  display: flex;
}

.cards .card .content .text {
  flex: 1;
  padding: 1rem;
}

.cards .card .content .imagen {
  max-width: 300px;
  height: auto;
  display: flex;
}

.cards .card .content .imagen img {
  display: inline-block;
}

.cards .card .content p {
  font-size: 1rem;
}

@media (max-width: 750px) {
  .cards .card h3 {
    margin-bottom: 0;
    padding-top: 0;
  }

  .cards .card .content {
    flex-direction: column;
    gap: 0;
    padding: 0;
    display: flex;
  }

  .cards .card .content .text {
    flex: 1;
    padding: 1rem;
  }

  .cards .card .content .imagen {
    max-width: none;
    width: 100%;
    height: auto;
  }
}

/*# sourceMappingURL=index.9568e1fe.css.map */
